@import 'antd/dist/reset.css';
@import 'react-toastify/dist/ReactToastify.css';
@import url("https://use.typekit.net/lcy8dyt.css");

.ant-layout, .ant-col, .ant-row, .ant-menu, .ant-card, .ant-table, .ant-checkbox-wrapper {
  color: #A4A8A9;
}

.ant-btn {
  color: #B5A766;
  background-color: transparent;
  border-color: #B5A766;
  text-transform: uppercase;
}

.ant-menu-light .ant-menu-item {
  color: #A4A8A9;
}

.ant-menu .ant-menu-item-active {
  color: #B5A766 !important;
}

.ant-menu .ant-menu-item-selected {
  color: #B5A766 !important;
  background-color: #000000;
}

.ant-layout, .ant-col, .ant-row, .ant-menu, .ant-card, .ant-btn, .ant-table, .ant-input, .ant-checkbox-wrapper, .ant-drawer {
  font-family: 'museo-sans', sans-serif !important;
}

.ant-layout-sider .ant-layout-sider-trigger {
  background-color: transparent;
}

.ant-form-item .ant-form-item-label >label {
  color: #A4A8A9;
}

.menu-items {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;

  &.show {
    max-height: 500px;
  }
}

.upload-full-width {
  display: block;
  width: 100%;

  .ant-upload {
    width: 100%;
  }

  .ant-btn {
    width: 100%;
  }
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.external-button-link {
  .ant-card-body {
    padding: 10px;
  }

  .ant-btn {
    background-color: #25282A;
  }
}

.ssn-radio-group {
  .ant-radio-button-wrapper {
    padding-inline: 10px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #B5A766;
    color: #B5A766;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #B5A766;
  }
}

.ant-list .ant-list-item {
  padding: 4px;
}

.header-link {
  .ant-btn {
    color: #FFFFFF !important;
    background-color: #B5A766;
    border-color: #FFFFFF;
    text-transform: uppercase;
  }
}

.font-row-sm .ant-table-cell {
  font-size: 12px;
}